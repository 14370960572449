import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import InpirationSlides from '../elements/InspirationSliderQuery'
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      >  
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      >  
      </div>
    );
  }
  
const Carousel = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        focusOnSelect: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        swipeToSlide: true,
        adaptiveHeight: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        swipeToSlide: true,
        centerMode: true,
        centerPadding: '0%',
        className:"inspiration-slider",
        onInit: function() {
            
        },
        onBeforeChange: function() {
            
        },
        onAfterChange: function() {
           
        },
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 1040,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 479,
            settings: {
              slidesToShow: 1,
              dots: true,
              
            }
          }
        ]
      };
    return (        
        <>
          <InpirationSlides category={props.category} settings={settings}></InpirationSlides> 
        </>
    );
};

export default Carousel;