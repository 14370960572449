import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Inspiration from "../views/Inspiration/Inspiration"
const InspirationPage = () => (
  <Layout overlay={false} navHeight="auto">
    <SEO title="Our Inspiration" url="https://www.kwazi.in/inspiration" />
    <Inspiration></Inspiration>
  </Layout>
)
export default InspirationPage

