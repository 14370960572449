import React, { useState } from 'react';
import styled, { css } from 'styled-components'
import slimg from '../images/img25.jpg'
import { CaseTitle } from '../static/css/style'
import ReadMore from '../elements/ReadMore'
import Paragraph from '../elements/Paragraph'
const SlideContainer = styled.div`
    width:100%;
    height:60vh;
    display:flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    justify-content:center;
    -webkit-box-pack: center;
  -webkit-justify-content: center;
  align-items:center;
  -webkit-align-items: center;
-ms-flex-align: center;
    overflow:hidden;
    @media screen and (max-width: 479px) {
        height:80vh;
    }
`;

const Summary = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size:1.1em;
    font-weight: normal;
    line-height:1.2;
    letter-spacing: normal;
    text-align: left;
    margin-bottom:0;
   
    @media screen and (max-width: 479px) {
        font-size:14px;
    }
    margin: 0;
    color: #fff;
    position: relative;
    z-index: 1;
`;
const Slide = (props) => {
    return (
        <SlideContainer>
            <div className="inspiration-slide-div" style={{ "backgroundImage": `url(${props.data.featuredImage.mediaItemUrl})` }}>
                <div className="opacity-parent">
                    <div className="inspiration-opacity-div">
                        <Summary>{props.data.summary}</Summary>
                        <ReadMore external="true" link={props.data.resourceLink} paddingLeft="0" top="3" color="white">read more</ReadMore>
                    </div>
                </div>
            </div>

        </SlideContainer>
    );
};

export default Slide;