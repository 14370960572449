import React, { Component } from 'react';
import { graphql, StaticQuery, Link } from "gatsby";
import Slide from '../elements/InspirationSlide'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allWpInspiration {
            nodes {
              inspiration_data {
                resourceLink
                summary
                featuredImage {
                  mediaItemUrl
                }
              }
              inspirationCategory {
                nodes {
                  name
                }
              }
            }
          }
          }
      `}

    render={data => (
      <Slider {...props.settings}>
        {data.allWpInspiration.nodes.map(({ dat_node }, index) => (
          data.allWpInspiration.nodes[index].inspirationCategory.nodes[0].name == props.category ?
            <Slide data={data.allWpInspiration.nodes[index].inspiration_data}></Slide>
            :
            ''
        ))}
      </Slider>
    )}
  />
)

