import React, { useState } from 'react';
import styled, { css } from 'styled-components';

//Elements 
import Slider from '../../elements/InspirationSlider';
import Title from '../../elements/Title';
import Para from '../../elements/Paragraph';
import Bikes from '../../images/bikes.jpg';
import Architecture from '../../images/architecture.jpg';
import Coffee from '../../images/coffee.jpg';
import Motorcycles from '../../images/motorcycles.jpg';
const ContentBlock = styled.div`
    width:90%;
    margin: auto;
    padding-top:9vw;
    padding-bottom:5em;
    @media screen and (max-width: 991px) {
        padding-top:120px;
    }
    @media screen and (max-width: 767px) {}
    @media screen and (max-width: 479px) {
        padding-top:75px;
        overflow:hidden;
    }
`;
const BannerSection = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction:column;
    -webkit-flex-direction: column;
  -ms-flex-direction: column;
    -webkit-box-pack: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    -webkit-box-align: left;
    -webkit-align-items: left;
    -ms-flex-align: left;
    align-items: left;    
    min-height:75vh;
    width:100%;
    @media screen and (min-width: 1920px) {
      height: 80vh;
    }
    @media screen and (max-width: 729px) {
      height: 100vh;
    }
    @media screen and (max-width: 479px) {
      height: 75vh;
    }
`;
const SliderBlock = styled.div`
    height:100vh;
    @media screen and (max-width: 479px) {
        height: 120vh;
      }
`;
const OpacityDiv = styled.div`
    padding: 2% 5% 5% 5%;
    background-color:rgba(0,0,0,0.5);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
    flex-direction:column;
    -webkit-flex-direction: column;
  -ms-flex-direction: column;
    -webkit-box-align: left;
    -webkit-align-items: left;
    -ms-flex-align: left;
    align-items: left;   
    width:100%;
    height:100%;
    @media screen and (max-width: 479px) {
        padding: 0% 5% 5% 5%;
        -webkit-box-pack: space-evenly;
        -webkit-justify-content: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    }
`;
const QuoteContainer = styled.div`
    position:relative;
    width:100%;
    height:100%;
    display:flex;
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
    flex-direction:row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    justify-content:flex-start;
    -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    transition:0.3s all ease;
    -webkit-transition:0.3s all ease;
    @media screen and (max-width: 479px) {
        flex-direction: column;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      align-items: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    justify-content:flex-end;
    -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  padding-top: 5vh;
    }
`;
const TitleContainer = styled.div`
    width:80%;
    @media screen and (max-width: 479px) {
      width:100%;
    }
`;

const Inspiration = (props) => {
    return (
        <div className="bodyBlack">
            <ContentBlock>
                <BannerSection>
                    <Title size="xl" weight="bold" color="#fff">inspiration..</Title>
                    <QuoteContainer>
                        <TitleContainer>
                            <Title size="lg" lineHeight="1.5" color="#fff">“Big ideas come from the unconscious. This is true in art, in science and advertising. But your unconscious has to be well informed, or your idea will be irrelevant. Stuff your conscious mind with information, then unhook your rational thought process”</Title>
                        </TitleContainer>
                        <Para color="#191919" fontWeight="bold" width="auto">~ David Ogilvy</Para>
                    </QuoteContainer>

                    {/* <Para LineHeight="1.71">Kwazi is a cohort of branding and design strategists. We love people who step out of their comfort zone. Each day millions of individuals strive for more, for better, for braver. We echo those same values. Giving an identity to those on the precipice of creating value, voice or impact, surfaces as our raison d' etre.</Para> */}
                </BannerSection>
            </ContentBlock>

            {/* <SliderBlock style={{"backgroundImage":`url(${Bikes})`}}>
                <OpacityDiv>    
                    <Title size="xl" weight="bold" color="#fff">all about wheels...</Title> 
                    <Slider category="Wheels"></Slider>  
                </OpacityDiv>
            </SliderBlock>   */}
            <SliderBlock style={{ "backgroundImage": `url(${Motorcycles})` }}>
                <OpacityDiv>
                    <Title size="xl" weight="bold" color="#fff">motorcycles and more...</Title>
                    <Slider category="Motorcycles"></Slider>
                </OpacityDiv>
            </SliderBlock>
            <SliderBlock style={{ "backgroundImage": `url(${Architecture})` }}>
                <OpacityDiv>
                    <Title size="xl" weight="bold" color="#fff">architecture and more...</Title>
                    <Slider category="Architecture"></Slider>
                </OpacityDiv>
            </SliderBlock>
            <SliderBlock style={{ "backgroundImage": `url(${Coffee})` }}>
                <OpacityDiv>
                    <Title size="xl" weight="bold" color="#fff">coffee and more...</Title>
                    <Slider category="Coffee"></Slider>
                </OpacityDiv>
            </SliderBlock>
        </div>
    );
};
export default Inspiration;